var __jsx = React.createElement;
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import styles from './HeaderWrapper.style';
import EmergencyBanner from '../../atoms/EmergencyBanner';
import GlobalHeader from '../../organisms/GlobalHeader';
import HeaderV2 from '../../organisms/HeaderV2';
var HeaderWrapper = function HeaderWrapper(_ref) {
  var _yextContactInformati;
  var className = _ref.className,
    site = _ref.site,
    yextContactInformationData = _ref.yextContactInformationData,
    removeHeader = _ref.removeHeader,
    globalHeaderData = _ref.globalHeaderData,
    siteData = _ref.siteData,
    newtheme = _ref.newtheme;
  var stickyEndRef = useRef(null);
  var stickyStartRef = useRef(null);
  var _useState = useState(false),
    isCollapsed = _useState[0],
    setIsCollapsed = _useState[1];
  useEffect(function () {
    if (globalHeaderData.collapseHeader) {
      var options = {
        rootMargin: '0px 0px 0px 0px',
        threshhold: 0
      };
      var observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting && entry.target.getAttribute('data-sticky') === 'start') {
            setIsCollapsed(false);
          } else if (!entry.isIntersecting && entry.target.getAttribute('data-sticky') === 'end') {
            setIsCollapsed(true);
          }
        });
      }, options);
      if (globalHeaderData !== null && globalHeaderData !== void 0 && globalHeaderData.stickyHeader || globalHeaderData !== null && globalHeaderData !== void 0 && globalHeaderData.stickyHeaderMobile) {
        stickyEndRef.current instanceof Element && observer.observe(stickyEndRef.current);
        stickyStartRef.current instanceof Element && observer.observe(stickyStartRef.current);
      }
      return function () {
        observer.disconnect();
      };
    }
  }, []);
  return __jsx(React.Fragment, null, __jsx("div", {
    ref: stickyStartRef,
    "data-sticky": "start",
    style: {
      position: 'absolute',
      top: 100,
      right: 0,
      height: 1,
      width: 1
    }
  }), __jsx("div", {
    ref: stickyEndRef,
    "data-sticky": "end",
    style: {
      position: 'absolute',
      top: 200,
      right: 0,
      height: 1,
      width: 1
    }
  }), __jsx("div", {
    className: "nva-header-wrapper ".concat(className, " ").concat(globalHeaderData !== null && globalHeaderData !== void 0 && globalHeaderData.stickyHeader ? 'is-sticky' : '', " ").concat(globalHeaderData !== null && globalHeaderData !== void 0 && globalHeaderData.stickyHeaderMobile ? 'is-sticky-mobile' : '', " ").concat(isCollapsed ? 'is-collapsed' : '')
  }, site !== null && site !== void 0 && site.easyEditorWebsite ? __jsx(EmergencyBanner, {
    className: "nva-header-wrapper__emergency-banner",
    text: (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_websiteEmergencyBanner) || (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : (_yextContactInformati = yextContactInformationData.entities) === null || _yextContactInformati === void 0 ? void 0 : _yextContactInformati[0].c_websiteEmergencyBanner) || '',
    backgroundColor: site === null || site === void 0 ? void 0 : site.emergencyBannerBackgroundColour,
    showBanner: site === null || site === void 0 ? void 0 : site.showEmergencyBanner
  }) : null, (globalHeaderData === null || globalHeaderData === void 0 ? void 0 : globalHeaderData.__typename) === 'GlobalHeader' && !removeHeader && __jsx(GlobalHeader, {
    className: "nva-header-wrapper__global-header",
    easyEditorWebsite: site.easyEditorWebsite,
    globalHeaderData: globalHeaderData,
    yextContactInformationData: yextContactInformationData,
    siteBusinessType: site.websiteType,
    headerLogo: siteData.logoLight,
    showContactInfoInFooter: siteData.showContactInfoInFooter,
    themeName: newtheme,
    isNvaMainSite: site.url === 'www.nva.com'
  }), (globalHeaderData === null || globalHeaderData === void 0 ? void 0 : globalHeaderData.__typename) === 'WebsiteHeaderV2' && !removeHeader && __jsx(HeaderV2, {
    header: globalHeaderData,
    yextContactInformationData: yextContactInformationData,
    headerLogo: siteData.logoLight,
    locationName: siteData.name
  })));
};
export default styled(HeaderWrapper).withConfig({
  componentId: "sc-30lrjm-0"
})(["", ";"], styles);