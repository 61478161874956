import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
function Clock(props) {
  return __jsx("svg", _extends({
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    fill: "none",
    "aria-hidden": "true"
  }, props), __jsx("g", {
    "clip-path": "url(#clip0_668_1097)"
  }, __jsx("path", {
    d: "M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z",
    stroke: "#49454F",
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }), __jsx("path", {
    d: "M8 4V8L10.6667 9.33333",
    stroke: "#49454F",
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  })), __jsx("defs", null, __jsx("clipPath", {
    id: "clip0_668_1097"
  }, __jsx("rect", {
    width: "16",
    height: "16",
    fill: "white"
  }))));
}
export default Clock;